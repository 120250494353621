/* *{
    padding: 0;
    margin: 0;
} */
.img-1 {
  width: 300px;
  height: 150px;
  padding-top: 10px;
  padding-left: 20px;
}

.logo-container {
  /* background-color: black; */

  margin-right: 150px;
}

.header-cell {
  cursor: pointer;
  background-color: #f8f8f8;
}

.header-content {
  display: flex;
  align-items: center;
}

.sort-icon {
  color: blue;
  margin-left: 5px;
}

.contact-detail {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-gap: 15px;  */
  margin: 20px;
}

.form-con {
  /* width: 100%; */

  /* border: 5px rgb(179, 130, 130) solid; */
}
.form-container {
  position: absolute;
  width: 100%;
  height: 100vh;

  padding: 0 40px;
}



.form-group {
  padding: 0 10px !important;
}

.search-admin input {
  /* line-height: 1.4375em; */
  font-size: 1rem;
  font-weight: 400;
  color: #212b36;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: inline-flex;
  align-items: center;
  position: relative;
  border-radius: 8px;
  width: 303px;
  transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  height: 55px;
  border: 1px solid #ccc;
}

.form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.error-message {
  color: red;
  font-weight: 400;
}

.popup-container {
  /* display: grid;
  grid-template-columns: repeat(2 ,1fr); */
  width: 100%;
}

.popup-grp {
  display: flex;
  /* display: inline; */
  justify-content: space-between !important;
  /* padding: 50px; */
  /* margin: 0 50px; */
  align-items: center;
}

.popup-control {
  width: 50% !important;
}

.extra-detail {
  display: flex;
  justify-content: space-between;
}

.input-er-con {
  display: flex;
  grid-gap: 10px;
}

.dropdown {
  display: block;
  /* margin-left: -20px; */
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da !important;
  /* appearance: none; */
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.heading-nw {
  font-size: 22px;
  font-weight: 600;
  color: #000;
  font-family: 'Poppins', sans-serif !important;
}

.label-con {
  font-weight: 500;
  padding-bottom: 10px;
  text-align: justify;
  float: center;
  color: #051330;
}

.label-con1 {
  font-weight: 500;
}

.popup-btn {
  border: 1px solid #ced4da !important;
  padding: 0px !important;
  width: 50px !important;
  padding: 0.375rem 0.75rem !important;
  background-color: white !important;
  /* margin-left: -15px; */
}
.th-perwk {
  /* margin-left: 16px !important; */
  text-align: center;
}

.signature-pad {
  position: relative;
  margin-top: 29px;
  border: 2px solid #eddbdb;
  width: 100%;
  height: 150px;
}

.signature-pad canvas {
  width: 100%;
  height: 100%;
  border: none;
}

.sign-button {
  width: 100px !important;
  background-color: #151414 !important;
  color: aliceblue !important;
}
.sign-button-1 {
  /* width: 100px !important; */
  background-color: #151414 !important;
  color: aliceblue !important;
  margin-left: 2px;
}

.submit-button {
  position: relative;

  float: left;

  width: 225px;
  height: 45px;
  background-color: #5d87ff !important;
}
.sign-res-btn {
  margin-top: 58px;
}
.sign-res-btn button {
  width: 194px !important;
  margin-bottom: 12px;
  border-color: transparent;
}
.chcek-box {
  float: left;
  margin-top: 7px;
}

.term {
  color: blue;
  text-decoration: underline;
}

.term-check {
  display: flex;
}

.chcek-box-con {
  position: absolute;
  /* display: flex; */
  /* right: 150px; */
  /* bottom: 100px; */
  /* display: ; */
}

.footer {
  position: relative;

  text-align: center;
  background-color: #151414;
  color: aliceblue;

  width: 100%;
  margin-bottom: 0;
}

.clickup-img .logo-img {
  width: 277px;
  margin: 0 auto;
  text-align: center;
}

.forget-img .logo-img {
  width: 100px;
}

.btn-clickup {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
/* //////////////////////////////////////////////////////////////// */
.authorization-card {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.clickup-div {
  background: #fff;
  padding: 86px;
  box-shadow: 1px 1px 10px 2px #ccc;
  border-radius: 7px;
  text-align: center;
}

.clickup-img {
  margin-bottom: 34px;
}

.tr-whitespace td {
  white-space: nowrap;
}

.btn-clickup {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  margin-top: 10px;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-clickup:hover {
  background-color: #0056b3;
}

/* //////////////////////////////////////////////////////////////////// */

.nodata-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10%;
}

.nodata-header {
  width: 80%;

  padding: 80px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
}

.nodata-header h4 {
  padding-bottom: 10px;
}

.address-container {
  position: relative;
  font-weight: 500;
  font-size: 18px;
  float: right;
  margin: 20px;
}

.text-con {
  text-align: center;
  position: relative;
  font-weight: 500;
  font-size: 30px;
  width: 100%;
  top: 40px;
  color: cornflowerblue;
}

.aggreement-con {
  align-items: center;
  height: 100%;
  border: 3px solid darkgrey;

  margin: 20px 50px;
  padding: 0 30px;
}

.agg-form {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
  top: 50px;

  margin-bottom: 50px;
  font-weight: 500;
  font-size: 18px;
  border-top: 2px solid grey;
  padding: 10px 0;
  width: 100%;
}

.agg-table {
  position: relative;
  top: 10px;
  width: 100%;
  font-size: 14px;
}

.table-responsive {
  width: 100% !important;
  background-color: #fff !important;
}

.tr-row {
  text-align: center;
}

.total {
  position: relative;
  /* top:0px; */
  font-weight: 500;
  font-size: 18px;
}

.last-term {
  float: right;
  font-size: 16px;
  font-weight: 500;
}

.term-con {
  position: relative;
  bottom: 10px;
  margin: 40px;
  margin: 0 50px;
  padding: 10px;
  font-weight: 200;
  font-size: 16px;
  margin-left: 280px;
}
.term-12 {
  color: red;
  text-decoration: underline;
}

.agg-form-input {
  color: cornflowerblue;
}

.form-table-div {
  /* border: 3px solid gray; */
}

.logo-address-con {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*
*  STYLE 2
*/

#style-2::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(90, 89, 89, 0.3);
  border-radius: 4px;
  background-color: #f5f5f5;
}

#style-2::-webkit-scrollbar {
  width: 6px !important;
  background-color: #f5f5f5;
  height: 5px;
}

#style-2::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 3px rgba(131, 131, 131, 0.3);
  background-color: #ebe0e0;
}
.add-btn {
  background: #5d87ff !important;
  border: none !important;
  font-weight: 600 !important;
  color: #ffffff !important;
}
.create-invo {
  background: #5d87ff !important;
  border: none !important;
  font-weight: 600 !important;
  color: #ffffff !important;
  margin-left: 10px;
  border-radius: 10px;
}

.update-btn {
  background: #991f1f !important;
  border: none !important;
  font-weight: 600 !important;
  color: #fff !important;
  margin-left: 14px;
}

.requirederor,
.error-message {
  color: red;
  font-size: 12px;
}
table th {
  white-space: nowrap;
}

.form-group {
  padding: 0 10px !important;
  margin-bottom: 18px;
  /* width: 150px; */
}
input[type='checkbox'] {
  position: relative;
  border: 2px solid #5d87ff;
  border-radius: 2px;
  background: none;
  cursor: pointer;
  line-height: 0;
  margin: 0 0.6em 0 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  opacity: 0.5;
}

input[type='checkbox']:hover {
  opacity: 1;
}

input[type='checkbox']:checked {
  background-color: #5d87ff;
  opacity: 1;
}

input[type='checkbox']:before {
  content: '';
  position: absolute;
  right: 50%;
  top: 50%;
  width: 4px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}
.radio label {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;
  /* Accessible outline */
  /* Remove comment to use */
  /*
  	&:focus-within {
  			outline: .125em solid $primary-color;
  	}
  */
}
.radio label input {
  position: absolute;
  left: -9999px;
}
.radio label input:checked + span {
  background-color: #d6d6e5;
}
.radio label input:checked + span:before {
  box-shadow: inset 0 0 0 0.4375em #5d87ff;
}
.radio label span {
  display: flex;
  align-items: center;
  padding: 0.375em 0.75em 0.375em 0.375em;
  border-radius: 99em;
  transition: 0.25s ease;
}
.radio label span:hover {
  background-color: #d6d6e5;
}
.radio label span:before {
  display: flex;
  flex-shrink: 0;
  content: '';
  background-color: #fff;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  margin-right: 0.375em;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.125em #5d87ff;
}
.radio {
  display: inline-flex;
  justify-content: start;
}
.search-admin input {
  margin-left: 0 !important;
}

/*150923 css*/
.form-area input {
  width: 100% !important;
}
.form-area select {
  padding: 5px;
  height: 57px;
  /* color: gray; */
}

.form-control-nw select.dropdown {
  height: 53px;
}
/* .dashhead .MuiPaper-root {
  background: #fff !important;
} */
.search-admin input,
.labal-content-form input {
  width: 396px;
}

.customer-edit-inpt input{
  width: 180px;
  padding: 0px 10px;
}




.btn {
  background: #f53333 !important;
  padding: 12px !important;
  border-radius: 10px !important;
}
.labal-content-form input {
  float: left;
}
.labal-content-form button {
  width: 150px;
  margin-left: 15px;
}

@media (max-width: 597px) {
  .labal-content-form input {
    margin-bottom: 15px;
  }
  .labal-content-form button {
    margin-left: 0px;
  }
  .search-admin input,
  .labal-content-form input {
    width: 100%;
  }
}

@media (max-width: 767px) {

  input.form-control.as {
    width: 223px;
}


  .form-area {
    padding: 0 !important;
    /* margin-bottom: 18px; */
    width: 228px;
  }
  .form-area input {
    width: 100% !important;
  }

  .heading-nw {
    font-size: 14px;
    margin-bottom:6px ;
  }

  .select-option-radio {
    font-size: 14px;
    font-weight: 200 !important;
    display:flex;
    margin-top: 32px;
  }

  .radio-container {
    margin-right: 3px !important;
  }

  .container-fluid {
    font-size: 14px !important;
  }
  .dashhead .MuiPaper-root {
    font-size: 12px;
  }

  .btn {
    margin-bottom: 10px;
    font-size: 12px;
  }
}


@media(max-width:375px){
  .form-control{
    font-size: 12px !important;
  }


  .dropdown {
    font-size: 14px !important;

  }

  .input .form-control{
    font-size: 14px !important;
  }
}