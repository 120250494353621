.heading{
  text-align: center;
  color:#5D87FF;

}

.img-ad{
  width: 150px;
  height: 70px;
  display: block;
 margin-left:20px;
 margin-top: 20px;

  

}
.admin-left-field{
margin-top: 80px;

text-align: center;
display: flex;
flex-direction: column;
gap: 10px;
/* border-bottom:1px solid black ; */
/* background: #000; */
}

.admin-left-div:hover{
background-color: orangered;
transition: 0.8s;


}

.admin-left-div{

/* border: none !important; */
padding: 5px 0;
}




.order{
background-color: aquamarine;
}


.img-con-ad{
  float: right;
  
}



.input{
  margin-left: 100px ;
  margin-top: 50px;
  border: 2px solid #5D87FF;
  border-radius: 7px;
}

.ad-table {
 
  width: 100%;

}

.plus-btn{
cursor: pointer;

}
.plus-btn:hover{
color: orangered;

}

/* .head-row{
border-top: 3px solid black ;
border-bottom: 3px solid black ;
line-height: 35px;
background: white;
color: rgb(13, 1, 1);
} */

.sec-head{
border-top: 1px solid black ;
border-bottom: 1px solid black ;
line-height: 15px;
}
.create-invo {
background: #5d87ff !important;
border: none !important;
font-weight: 600 !important;
color: #ffffff !important;
}
.create-invo a{
color: #ffffff !important;
text-decoration:none;
}
.pagination {
display: flex;
margin-top: 20px;
position: sticky;
bottom: 10px;
right: 0;
justify-content: center;
}

.pagination li {
display: inline-block;
margin: 0 5px;
}

.pagination button {
background-color: #fff;
color: orangered;
border: 1px solid orangered;
border-radius: 5px;
padding: 5px 10px;
}

.pagination button:hover {
background-color: orangered;
color: #fff;
}

.pagination .active button {
background-color: orangered;
color: #fff;
border: 1px solid orangered;
}


.view-img{
width: 25px;

}


.view-img:hover{
color:  orangered;
cursor: pointer;
}


.form-link{
position: absolute;
top:20px;
padding: 8px;
border: 2px solid black;
text-decoration: none;
 /* background-color: ; */
border-radius: 8px;
right: 80px;

}
.form-link-2{
position: absolute;
top:20px;
padding: 8px;
border: 2px solid black;
text-decoration: none;
 /* background-color: ; */
border-radius: 8px;
left : 80px;

}

a{
text-decoration: none;
color:black;
}

a:hover{
color: orangered;
}

.logout{
position: absolute;
top:21px;
padding: 3px 20px;
padding-bottom: 2px;
cursor: pointer;

border-radius: 8px;
right: 10px;
}

.admin-left{
border: 1px solid black;
border-radius: 20px;

}

td.bt-design button {
border: none;
background: none;
width: 100%;
text-align: center;
position: relative;
}

td.bt-design button.Active:before {
content: '';
margin-left: -18px;
position: absolute;
width: 12px;
height: 12px;
background: #3c8b0a;
border-radius: 50px;
left: 19px;
display: flex;
align-items: center;
top: 5px;
}
td.bt-design button.Inactive:before{
content: '';
margin-left: -18px;
position: absolute;
width: 12px;
height: 12px;
background: #a51010;
border-radius: 50px;
left: 13px;
display: flex;
align-items: center;
top: 5px;
}



@media(max-width:375px){
  
.img-con-ad{
  float: left;
  
}


}



@media(max-width:1075px){
  td.bt-design button {
    border: none;
    background: none;
    width: 100%;
    text-align: center;
    position: relative;
    margin-left: 29px;
  }
}
@media(max-width:767px){

  .cont-tainer{
    font-size: 12px !important;
  }
}