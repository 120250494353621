.auth-form-container {
  max-width: 545px;
  margin: 0 auto;
}



.container-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-bottom: 20px;
}

.container-grid-2 {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
}

.sign-res-btn-res {
  display: flex;
}



.signature-pad-res canvas {
 

  width: 100%;
  height: 100%;
  border: none;
}

.signature-pad-res {
  position: relative;
  margin-top: 50px;
  border: 2px solid #151414;
  width: 65%;
 

  height: 130px;
  bottom: 50px;
  left: 12px;
}

.sign-resigter {
  width: 200px;
}

.form-control-log {
  width: 100%;
  height: calc(2.5rem + 2px);
  padding: 2px 8px;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
 
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* .form-control-log:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem#fff } */

.form-control-log-phn {
  width: 100%;
  height: calc(2.5rem + 2px);
  padding: 2px 8px;
  font-size: 1rem;
  line-height: 1.5;
  /* color: #495057; */
  background-color: #fff;
  /* background-clip: padding-box; */
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control-log-phn:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem#0d6efd;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.invalid-feedback-er {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #eef13b !important;
}

.btn-1 {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: #0077ff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn-1:hover {
  background-color: #0056b3;
}

.btn-1:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 119, 255, 0.5);
}

.btn-1:active {
  transform: translateY(1px);
  box-shadow: none;
}

.auth-form-container h2 {
  text-align: center;
  font-size: 23px;
  margin-bottom: 0px;
}
.auth-form-container .link-btn {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
}

.auth-form-container .link-btn a {
  font-size: 14px;
  color: #c4d14c !important;
  font-weight: 400;
}

button.btn-1.btn.btn-primary {
  width: 150px;
}
.auth-form-container h6 {
  font-size: 14px;
}

.link-btn {
  display: inline-block;
  padding: 10px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #0077ff;
  text-decoration: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.link-btn:hover {
  color: #0056b3;
  transform: translateY(-3px);
}

.link-btn:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 119, 255, 0.5);
}

.link-btn:active {
  transform: translateY(0);
}

.sign-button-res,
.sign-button-1-res {
  padding: 8px 16px;
  margin-right: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}

.sign-button-res:hover,
.sign-button-1-res:hover {
  background-color: #0069d9;
}

.grid-login .logo-img {
  width: 100px;
  height: 50px;
}

.grid-regis .logo-img {
  width: 100px;
  height: 50px;
}

.forget-img .logo-img {
  width: 100px;
  height: 50px;
}

.reset-img .logo-img {
  width: 100px;
  height: 50px;
}

@media (max-width: 480px) {
  .auth-form-container .link-btn {
    margin: 0 auto;
    width: 100%;
    float: none !important;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .container-grid {
    grid-template-columns: 1fr;
  }

  .label-input-div {
    /* margin: 0 10px; */
  }

  .col {
    margin-bottom: 20px;
  }

  .signature-pad-res {
    height: 200px;
  }

  .sign-res-btn-res {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .sign-button-res,
  .sign-button-1-res {
    flex: 0.5;
    margin: 0px;
  }
}

@media (min-width: 768px) {
  .sign-res-btn-res {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .sign-button-res,
  .sign-button-1-res {
    margin: 0px;
    width: 40%;
  }
}
